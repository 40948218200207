import React from "react";

import SEO from "../components/seo";
import Hero from "../sections/common/Hero";
import Roles from "../sections/career/Roles";
import Layout from "../components/Blog/Layout";

const Career = () => {
  return (
    <React.Fragment>
      <SEO title="MARK AI - Career" />

      <Layout>
        <Hero title="Work at Mark">
          If you want to have an impact, you've come at the right place.
        </Hero>
        <Roles />
      </Layout>
    </React.Fragment>
  );
};
export default Career;
